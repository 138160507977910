
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import {DrawerComponent} from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "ActivityProgressModal",
  props: {
    data: { type: Object },
  },
  setup(props) {
    const { bus } = useBus();
    const formData = ref({
      id: "",
      updated_by: "",
      ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") !== "null"
          ? VueCookieNext.getCookie("_ia_partner_id")
          : 0,
      created_by: VueCookieNext.getCookie("_user_id"),
      activity_id: "",
      output_id: "",
      location: "",
      summery_output: "",
      physical_progress: "",
      financial_progress: "",
      progress_details: "",
      challenges: "",
      remarks: "",
      date: "",
      activity: {
        output: {
          id: 0,
        },
      },
    });

    watch(
        () => props.data,
        (newData) => {
          if (newData) {
            formData.value = {
              ...formData.value,
              ...newData,
            };

            formData.value.output_id = formData.value.activity.output.id.toString();
          }
        }
    );

    const schema = object().shape({
      activity_id: number().required("Activity is required"),
    });

    const errors = ref({});
    const outputOptions = ref([]);
    const activityOptions = ref([]);


    watch(
      () => formData.value.output_id,
      (newComponent) => {
        if (newComponent !== "") {
          getOutputWiseActivity();
        }
      }
    );

    const getOutput = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_OUTPUT_LIST,
            {
              ia_partner_id:
                  VueCookieNext.getCookie("_ia_partner_id") !== "null"
                      ? VueCookieNext.getCookie("_ia_partner_id")
                      : 0,
            }
        );

        // console.log(response.data.data);
        outputOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.title,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getOutputWiseActivity = async () => {
      try {

        if(formData.value.output_id == '0' || formData.value.output_id == ''){
          ElNotification({
            dangerouslyUseHTMLString: true,
            message: 'Please select output',
            type: "error",
          });

          return;
        }

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_ACTIVITY_LIST,
            {
              output_id: formData.value.output_id,
            }
        );

        activityOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.title,
        }));
      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    const save = async () => {
      try {
        if (formData.value.id) {
          formData.value.updated_by = VueCookieNext.getCookie("_user_id");
        }

        await schema.validate(formData.value, { abortEarly: false });

        ApiService.post(
          apiEndpoint.data().VUE_APP_ACTIVITY_PROGRESS_STORE,
          formData.value
        )
          .then((response) => {
            console.log(response.data);
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              DrawerComponent.hideAll();
              bus.emit("loadData", true);
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        formData.value = {
          id: "",
          updated_by: "",
          ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") !== "null"
              ? VueCookieNext.getCookie("_ia_partner_id")
              : 0,
          created_by: VueCookieNext.getCookie("_user_id"),
          activity_id: "",
          output_id: "",
          location: "",
          summery_output: "",
          physical_progress: "",
          financial_progress: "",
          progress_details: "",
          challenges: "",
          remarks: "",
          date: "",
          activity: {
            output: {
              id: 0,
            },
          },
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    onMounted(getOutput);

    return {
      formData,
      outputOptions,
      activityOptions,
      errors,
      save,
    };
  },
});
